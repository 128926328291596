import { ValidationErrors } from "final-form";
import { LocalDate } from "js-joda";
import {
  FieldConfigDto,
  getMandatoryFields,
  getOptionalFields,
} from "../../store/models/FieldConfigDto";
import {
  RegistrationDto,
  REGISTRATION_FIELDS,
} from "../../store/models/RegistrationDto";
import { ConfigDto } from "../../store/models/ConfigDto";

function getRegistrationValueByName(
  registration: RegistrationDto,
  propertyName: string
) {
  return (
    registration &&
    propertyName &&
    propertyName
      .split(".")
      .reduce(
        (result, prop) => (result == null ? undefined : result[prop]),
        registration as any
      )
  );
}
function setRegistrationErrors(
  errors: ValidationErrors,
  errorMessage: string,
  propertyName: string
) {
  if (!errors) return;
  if (!propertyName) return errors;
  const props = propertyName.split(".");

  if (props.length === 1) {
    errors[props[0]] = errorMessage;
  } else if (props.length === 2) {
    if (!errors[props[0]]) {
      errors[props[0]] = {};
    }
    errors[props[0]][props[1]] = errorMessage;
  }
}

export const MANDATORY_TEXT = "Dieses Feld ist ein Pflichtfeld.";

export function isFieldMandatory(
  fieldName: string,
  registrationFieldConfig: FieldConfigDto[]
) {
  if (!registrationFieldConfig.length) return undefined;
  const mandatoryFields = getMandatoryFields(registrationFieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");
  return mandatoryFields.some(
    (mandatoryField) => mandatoryField === fieldKeyFromName
  );
}
export function isFieldOptional(
  fieldName: string,
  registrationFieldConfig: FieldConfigDto[]
) {
  if (!registrationFieldConfig.length) return undefined;

  const optionalFields = getOptionalFields(registrationFieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return optionalFields.some(
    (mandatoryField) => mandatoryField === fieldKeyFromName
  );
}
export function isFieldNeeded(
  fieldName: string,
  registrationFieldConfig: FieldConfigDto[]
) {
  if (!registrationFieldConfig.length) return true;
  return (
    isFieldMandatory(fieldName, registrationFieldConfig) ||
    isFieldOptional(fieldName, registrationFieldConfig)
  );
}
function getMandatoryMap(registrationFieldConfig: FieldConfigDto[]): string[] {
  const mandatoryFields = getMandatoryFields(registrationFieldConfig);
  return mandatoryFields.map((prop) => (REGISTRATION_FIELDS as any)[prop]);
}
export function validateMandatory(
  errors: ValidationErrors,
  registration: RegistrationDto,
  registrationFieldConfig: FieldConfigDto[]
) {
  const mandatoryMap = getMandatoryMap(registrationFieldConfig);

  mandatoryMap.forEach((propertyName) => {
    if (!getRegistrationValueByName(registration, propertyName)) {
      // temporary workaround for newsletter
      if (propertyName !== REGISTRATION_FIELDS.EMAILCORRESPONDENCEALLOWED)
        setRegistrationErrors(errors, MANDATORY_TEXT, propertyName);
    }
  });

  return errors;
}

export function formatName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .replace(/(^| |-)(?!de|der|van|von|zur|zum|zu)(\w)/g, (s) =>
      s.toUpperCase()
    );
}

export function validateNameLength(name: string): boolean {
  return name.split(/[ ,-]+/).every((a) => a.length > 1);
}

export function validateLatin(name: string): boolean {
  return /^[a-zA-ZÀ-ÿ\-'’` ]*$/.test(name);
}

export function validateDateOfBirth(
  errors: ValidationErrors,
  dateOfBirth?: string
) {
  if (!errors) return;
  if (dateOfBirth) {
    try {
      const date = LocalDate.parse(dateOfBirth);
      if (date.isAfter(LocalDate.now().minusYears(18))) {
        errors.dateOfBirth = "Sie müssen mindestens 18 Jahre alt sein";
      }
      if (date.year() < 1900) {
        errors.dateOfBirth = "Das Geburtsdatum ist ungültig";
      }
    } catch (error) {
      errors.dateOfBirth = "Das Geburtsdatum ist ungültig";
    }
  }
  return errors;
}

export function validateName(
  errors: ValidationErrors,
  firstName?: string,
  lastName?: string
) {
  if (!errors) return;

  if (firstName) {
    firstName = formatName(firstName);
    if (!validateNameLength(firstName)) {
      errors.firstName =
        "Der Vorname muss mindestens zwei Buchstaben enthalten";
    }
    if (!validateLatin(firstName)) {
      errors.firstName =
        "Der Vorname darf nur lateinische Buchstaben enthalten";
    }
  }
  if (lastName) {
    lastName = formatName(lastName);
    if (!validateNameLength(lastName)) {
      errors.lastName =
        "Der Nachname muss mindestens zwei Buchstaben enthalten";
    }
    if (!validateLatin(lastName)) {
      errors.lastName =
        "Der Nachname darf nur lateinische Buchstaben enthalten";
    }
  }
  return errors;
}

export function validateRegistration(
  errors: ValidationErrors,
  password: string,
  passwordConfirm: string | undefined
) {
  if (!errors) return;

  if (!errors.login) {
    errors.login = {};
  }

  if (password && passwordConfirm && password !== passwordConfirm) {
    errors.login.passwordConfirm =
      "Die Passwortbestätigung ist nicht identisch.";
  }
  if (!passwordConfirm) {
    errors.login.passwordConfirm = MANDATORY_TEXT;
  }

  return errors;
}

export function isVisibleField(
  fieldName: string,
  displayConfig: ConfigDto[]
) {
  if (!displayConfig.length) return true;
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return displayConfig.some(
    (config) => config.key === fieldKeyFromName && config.value.toLocaleLowerCase() === "true"
  );
  }