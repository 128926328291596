import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { isLoggedIn, thunkLogin } from "../../store/actions/LoginActions";
import { LoginDto } from "../../store/models/LoginDto";
import { IStore } from "../../store/rootReducer";
import { CenterLayout } from "../../themes/SwitchTheme";
import {
  UrlPathForgotPassword,
  UrlPathMain,
  UrlPathRegistration,
} from "../../url";
import { nameof } from "../app/Utils";
import NotificationList from "../notification/NotificationList";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { MANDATORY_TEXT } from "../registration/Validation";

type ThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const LoginPage = (props: ThunkProps) => {
  const navigate = useNavigate();
  const validate = (login: LoginDto) => {
    const errors: ValidationErrors = {};
    if (!login.username) errors.username = MANDATORY_TEXT;
    if (!login.password) errors.password = MANDATORY_TEXT;
    return errors;
  };

  const redirectToMain = () => {
    navigate(UrlPathMain);
    window.scrollTo(0, 0);
  };

  const login = async (loginDto: LoginDto) => {
    const { thunkLogin } = props;
    await thunkLogin(loginDto);
    redirectToMain();
  };

  if (isLoggedIn()) redirectToMain();
  return (
    <CenterLayout>
      <NotificationList />
      <div className="jumbotron">
        <h1>Anmeldung</h1>
        <Form
          onSubmit={login}
          initialValues={{} as LoginDto}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <PaliFinalFormInput
                    type="email"
                    name={nameof<LoginDto>("username")}
                    label="E-Mail"
                    autoComplete="email"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PaliFinalFormInput
                    type="password"
                    name={nameof<LoginDto>("password")}
                    label="Passwort"
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    type="submit"
                    className="float-right"
                  >
                    Anmelden
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
      <Row>
        <Col>
          <NavLink to={UrlPathForgotPassword}>Passwort vergessen?</NavLink>
        </Col>
        <Col>
          <div className="float-right">
            Neuer Benutzer?{" "}
            <NavLink to={UrlPathRegistration}>Registrierung</NavLink>
          </div>
        </Col>
      </Row>
    </CenterLayout>
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkLogin }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
