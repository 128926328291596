import React from "react";
import PersonalData from "./PersonalData";
import Contact from "./Contact";
import { Button, Col, Row } from "reactstrap";
import { CustomerDto } from "../../store/models/CustomerDto";
import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import { validateMandatory } from "./Validation";
import { CustomerFormThunkProps } from "./CustomerFormContainer";
import { validateName } from "../registration/Validation";
import MembershipCancellationDialog from "./MembershipCancellationDialog";

export default class CustomerForm extends React.Component<CustomerFormThunkProps> {
  constructor(props: CustomerFormThunkProps) {
    super(props);
    this.state = { customer: props.customer };
  }

  validate = (customer: CustomerDto) => {
    const { customerFieldConfig } = this.props;
    const errors: ValidationErrors = {};

    validateMandatory(errors, customer, customerFieldConfig);
    validateName(errors, customer.firstName, customer.lastName);
    return errors;
  };

  render() {
    const { customer, thunkUpdateCustomer } = this.props;

    return (
      <>
        <div>
          <Form
            onSubmit={thunkUpdateCustomer}
            initialValues={customer}
            validate={this.validate}
            render={({ handleSubmit }) => {
              const [
                membershipCancellationDialogVisible,
                setMembershipCancellationDialogVisible,
              ] = React.useState(false);
              return (
                <>
                  {membershipCancellationDialogVisible && (
                    <MembershipCancellationDialog
                      setMembershipCancellationDialogVisible={
                        setMembershipCancellationDialogVisible
                      }
                    />
                  )}
                  <form onSubmit={handleSubmit}>
                    <PersonalData {...this.props} />
                    <Contact {...this.props} />
                    <Row>
                      <Col md={{ size: "auto" }}>
                        <Button type="submit">Speichern</Button>
                      </Col>
                      <Col md={{ size: "auto" }} className="ms-auto">
                        <Button
                          onClick={() => {
                            setMembershipCancellationDialogVisible(true);
                          }}
                        >
                          Kündigen
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </>
              );
            }}
          />
        </div>
      </>
    );
  }
}
