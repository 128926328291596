import API from "../../api/Api";
import { BooleanThunk } from "../rootReducer";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY";
export const GET_BONUS_POINTS_INFO = "GET_BONUS_POINTS_INFO";

export const thunkGetCustomerHistory =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const bonifiedInteractions = await API.getCustomerHistory(customerNumber);
      dispatch({
        type: GET_CUSTOMER_HISTORY,
        payload: bonifiedInteractions,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Bonushistorie", e)
      );
      return false;
    }
  };

export const thunkGetBonusPointsInfo =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const result = await API.getBonusPointsInfo(customerNumber);
      dispatch({
        type: GET_BONUS_POINTS_INFO,
        payload: result,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Bonuspunkte", e)
      );
      return false;
    }
  };
