import API from "../../api/Api";
import { InvitationType } from "../models/InvitationDto";
import { BooleanThunk } from "../rootReducer";
import { ensureToken } from "./LoginActions";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const thunkInviteFamily =
  (mailAddress: string): BooleanThunk =>
  async (dispatch) => {
    const token = ensureToken();
    if (!token) return false;
    try {
      await API.invite({
        customerNumber: token.customer_number,
        mailAddress: mailAddress,
        invitationType: InvitationType.INVITE_TO_FAMILY,
      });
      dispatch(
        thunkCreateSuccessNotification(
          `Familienmitglied eingeladen: ${mailAddress}`
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Einladen eines Familienmitglieds",
          e
        )
      );
      return false;
    }
  };

export const thunkInviteFriend =
  (mailAddress: string): BooleanThunk =>
  async (dispatch) => {
    const token = ensureToken();
    if (!token) return false;
    try {
      await API.invite({
        customerNumber: token.customer_number,
        mailAddress: mailAddress,
        invitationType: InvitationType.REFER_A_FRIEND,
      });
      dispatch(
        thunkCreateSuccessNotification(
          `Freundin / Freund eingeladen: ${mailAddress}`
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Einladen einer Freundin / eines Freundes",
          e
        )
      );
      return false;
    }
  };
