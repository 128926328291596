import { Gender } from "./Gender";
import AddressDto from "./AddressDto";
import { nameof, nameof2 } from "../../components/app/Utils";

export interface CustomerDto {
  customerNumber: string;
  externalCustomerId?: string;
  gender?: Gender;
  title?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  mailAddress: string;
  phoneNumber?: string;
  mobileNumber?: string;
  address?: AddressDto;
  emailCorrespondenceAllowed?: boolean;
  letterCorrespondenceAllowed?: boolean;
  masterUnitNumber?: string;
}
export const CUSTOMER_FIELDS = {
  CUSTOMERNUMBER: nameof<CustomerDto>("customerNumber"),
  EXTERNALCUSTOMERID: nameof<CustomerDto>("externalCustomerId"),
  GENDER: nameof<CustomerDto>("gender"),
  TITLE: nameof<CustomerDto>("title"),
  FIRSTNAME: nameof<CustomerDto>("firstName"),
  LASTNAME: nameof<CustomerDto>("lastName"),
  DATEOFBIRTH: nameof<CustomerDto>("dateOfBirth"),
  MAILADDRESS: nameof<CustomerDto>("mailAddress"),
  PHONENUMBER: nameof<CustomerDto>("phoneNumber"),
  MOBILENUMBER: nameof<CustomerDto>("mobileNumber"),
  ADDRESS_STREETNAME: nameof2<CustomerDto, AddressDto>("address", "streetName"),
  ADDRESS_HOUSENUMBER: nameof2<CustomerDto, AddressDto>(
    "address",
    "houseNumber"
  ),
  ADDRESS_ADDRESSADDITION: nameof2<CustomerDto, AddressDto>(
    "address",
    "addressAddition"
  ),
  ADDRESS_ZIPCODE: nameof2<CustomerDto, AddressDto>("address", "zipCode"),
  ADDRESS_CITYNAME: nameof2<CustomerDto, AddressDto>("address", "cityName"),
  ADDRESS_COUNTRY: nameof2<CustomerDto, AddressDto>("address", "country"),

  EMAILCORRESPONDENCEALLOWED: nameof<CustomerDto>("emailCorrespondenceAllowed"),
  LETTERCORRESPONDENCEALLOWED: nameof<CustomerDto>("letterCorrespondenceAllowed"),
  MASTERUNITNUMBER: nameof<CustomerDto>("masterUnitNumber"),
};
