import API from "../../api/Api";
import { ConfigGroupKey } from "../models/ConfigGroupKey";
import { CustomerDto } from "../models/CustomerDto";
import { ConfigTypes } from "../models/FieldConfigDto";
import { BooleanThunk, BooleanThunkAction } from "../rootReducer";
import { thunkGetBonusPointsInfo } from "./BonifiedInteractionsActions";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const FIND_CUSTOMER = "FIND_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CUSTOMER_DISPLAY_CONFIG = "CUSTOMER_DISPLAY_CONFIG";
export const CUSTOMER_FIELD_CONFIG = "CUSTOMER_FIELD_CONFIG";

export const thunkFindCustomer = (): BooleanThunk => async (dispatch) => {
  try {
    const customer = await API.findCustomer();
    dispatch({
      type: FIND_CUSTOMER,
      payload: customer,
    });
    return true;
  } catch (e) {
    dispatch(
      thunkCreateErrorNotification("Fehler beim Laden der Kundendaten", e)
    );
    return false;
  }
};

export const thunkUpdateCustomer =
  (customer: CustomerDto): BooleanThunk =>
  async (dispatch) => {
    // remove preselected country
    if (
      customer.address &&
      customer.address.country === "DE" &&
      !customer.address.cityName &&
      !customer.address.houseNumber &&
      !customer.address.streetName &&
      !customer.address.zipCode
    )
      delete customer.address;
    try {
      const updatedCustomer = await API.updateCustomer(customer);
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: updatedCustomer,
      });
      // update bonus points with delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(
        thunkGetBonusPointsInfo(customer.customerNumber) as BooleanThunkAction
      );
      dispatch(
        thunkCreateSuccessNotification(`Die Kundendaten wurden aktualisiert`)
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren der Kundendaten",
          e
        )
      );
      return false;
    }
  };
export const thunkGetCustomerConfig =
  (): BooleanThunk => async (dispatch) => {
    try {
      const customerConfig = await API.getCustomerConfig(
        [ConfigGroupKey.CUSTOMER_CARE_FIELD_DISPLAY_CONFIG]
      );
      dispatch({
        type: CUSTOMER_DISPLAY_CONFIG,
        payload: customerConfig,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Ermitteln der Feldkonfiguration",
          e
        )
      );
      return false;
    }
  };
export const thunkGetCustomerFieldConfig =
  (): BooleanThunk => async (dispatch) => {
    try {
      const customerFieldConfig = await API.getCustomerFieldConfig(
        ConfigTypes.LOYALTY
      );
      dispatch({
        type: CUSTOMER_FIELD_CONFIG,
        payload: customerFieldConfig,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Ermitteln der Feldkonfiguration",
          e
        )
      );
      return false;
    }
  };
