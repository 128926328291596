import CustomerForm from "./CustomerForm";
import { IStore } from "../../store/rootReducer";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkGetCustomerConfig,
  thunkUpdateCustomer,
} from "../../store/actions/CustomerActions";
import { thunkCancelMembership } from "../../store/actions/MembershipCancellationActions";
import { useState } from "react";
import { useMountEffect } from "../app/Utils";

const CustomerFormContainer = (props: CustomerFormThunkProps) => {
  const [loadingData, setLoadingData] = useState(false);
  useMountEffect(() => {
    async function getCustomerDisplayConfig() {
      if (!props.customerDisplayConfig?.length) {
        await props.thunkGetCustomerConfig();
        setLoadingData(true);
      }
    }
    getCustomerDisplayConfig();
  });
  return <>{loadingData && <CustomerForm {...props} />} </>;
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customer.customer,
  branchStores: state.operationalUnit.branchStores,
  customerDisplayConfig: state.customer.customerDisplayConfig,
  customerFieldConfig: state.customer.customerFieldConfig,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkUpdateCustomer, thunkCancelMembership, thunkGetCustomerConfig },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CustomerFormThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerFormContainer);
