import React from "react";
import { connect } from "react-redux";
import { IStore } from "../../store/rootReducer";
import { BonusPremiumDto } from "../../store/models/BonusPremiumDto";
import { PaliTable } from "../pali/PaliTable";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { PaliInput } from "../pali/PaliInput";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { thunkCreateCoupon } from "../../store/actions/CouponActions";
import { ensureToken } from "../../store/actions/LoginActions";

const tableColumns = (openCreation: (template: BonusPremiumDto) => void) => [
  {
    dataField: "advantage.advantageText",
    text: "Name",
    sort: true,
  },
  {
    dataField: "bonusPoints",
    text: "Bonuspunkte",
  },
  {
    dataField: "",
    text: "",
    formatter: (cell: any, template: BonusPremiumDto) => {
      return <Button onClick={() => openCreation(template)}>Aktivieren</Button>;
    },
  },
];

const MAX_RESULTS_IN_TABLE = 10;

type ThunkProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface BonusPremiumTableState {
  pagedTemplates: BonusPremiumDto[];
  selectedTemplate?: BonusPremiumDto;
  searchTerm: string;
  currentPage: number;
  maxPage: number;
  totalEntries: number;
  isCreationOpen: boolean;
}

export class BonusPremiumTable extends React.Component<
  ThunkProps,
  BonusPremiumTableState
> {
  constructor(props: ThunkProps) {
    super(props);
    this.state = {
      pagedTemplates: [],
      searchTerm: "",
      currentPage: 1,
      maxPage: 1,
      totalEntries: 0,
      isCreationOpen: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: ThunkProps,
    state: BonusPremiumTableState
  ) {
    if (
      state.searchTerm === "" &&
      state.totalEntries !== nextProps.templates.length
    ) {
      return {
        pagedTemplates: BonusPremiumTable.paginate(nextProps.templates, 1),
        maxPage: BonusPremiumTable.fetchMaxPage(nextProps.templates),
        currentPage: 1,
        totalEntries: nextProps.templates.length,
      };
    } else return null;
  }

  goToPage = (pageNumber: number) => () => {
    this.setState({
      currentPage: pageNumber,
      pagedTemplates: BonusPremiumTable.paginate(
        this.searchList(this.state.searchTerm),
        pageNumber
      ),
    });
  };

  static fetchMaxPage(arr: any[]) {
    return Math.floor((arr.length - 1) / MAX_RESULTS_IN_TABLE) + 1;
  }

  static paginate(array: any[], page: number) {
    --page;
    return array.slice(
      page * MAX_RESULTS_IN_TABLE,
      (page + 1) * MAX_RESULTS_IN_TABLE
    );
  }

  handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    const searchResult = this.searchList(searchTerm);
    const pagedTemplates = BonusPremiumTable.paginate(searchResult, 1);
    this.setState({
      searchTerm,
      pagedTemplates,
      maxPage: BonusPremiumTable.fetchMaxPage(searchResult),
      currentPage: 1,
    });
  };

  searchList = (searchTerm: string) => {
    let newTemplates = this.props.templates;
    if (searchTerm.length) {
      newTemplates = newTemplates.filter((template) =>
        template.advantage.advantageText
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }
    return newTemplates;
  };

  openCreation = (template: BonusPremiumDto) => {
    this.setState({
      isCreationOpen: true,
      selectedTemplate: template,
    });
  };

  toggleCreation = () => {
    this.setState((prevState) => ({
      isCreationOpen: !prevState.isCreationOpen,
    }));
  };

  create = () => {
    const { thunkCreateCoupon } = this.props;
    const { selectedTemplate } = this.state;

    if (!selectedTemplate) return;
    const token = ensureToken();
    if (!token) return;
    thunkCreateCoupon(selectedTemplate.templateNumber, token.customer_number);

    this.toggleCreation();
  };

  render() {
    const {
      currentPage,
      maxPage,
      pagedTemplates,
      selectedTemplate,
      searchTerm,
      isCreationOpen,
    } = this.state;
    const { templates } = this.props;

    if (!templates.length) {
      return <p>Sie haben noch keine Prämiencoupons.</p>;
    }
    return (
      <Row>
        <Col sm={3}>
          <PaliInput
            type="text"
            label="Prämie suchen"
            value={searchTerm}
            handleChange={this.handleSearchTermChange}
            name="searchTerm"
          />
        </Col>
        <Col sm={12}>
          <PaliTable
            columns={tableColumns(this.openCreation)}
            data={pagedTemplates.map((template, i) => ({
              ...template,
              id: i,
            }))}
          />
          <Pagination size="sm" hidden={maxPage < 2}>
            <PaginationItem>
              <PaginationLink
                previous
                disabled={currentPage <= 1}
                onClick={this.goToPage(currentPage - 1)}
              />
            </PaginationItem>

            {Array.from(Array(maxPage).keys()).map((i) => (
              <PaginationItem
                key={"paginationItem" + i}
                active={i + 1 === currentPage}
              >
                <PaginationLink onClick={this.goToPage(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationLink
                next
                disabled={currentPage >= maxPage}
                onClick={this.goToPage(currentPage + 1)}
              />
            </PaginationItem>
          </Pagination>
        </Col>
        <Modal isOpen={isCreationOpen}>
          <ModalHeader>Prämiencoupon aktivieren</ModalHeader>
          <ModalBody>
            {selectedTemplate && (
              <>{selectedTemplate.advantage.advantageText}</>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.create}>Aktivieren</Button>
            <Button onClick={this.toggleCreation}>Schließen</Button>
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  templates: state.bonusPremium.bonusPremiums,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkCreateCoupon }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BonusPremiumTable);
