import { CustomerInteractionType } from "./CustomerInteractionType";

export enum InteractionType {
  PAYMENT_INTERACTION = "PAYMENT_INTERACTION",
  CUSTOMER_INTERACTION = "CUSTOMER_INTERACTION",
  COUPON_INTERACTION = "COUPON_INTERACTION",
  BONUS_XPRESS_INTERACTION = "BONUS_XPRESS_INTERACTION",
  REVERSAL_INTERACTION = "REVERSAL_INTERACTION",
  BONUS_XPRESS_REVERSAL_INTERACTION = "BONUS_XPRESS_REVERSAL_INTERACTION",
  ADJUSTMENT_EARN_INTERACTION = "ADJUSTMENT_EARN_INTERACTION",
  ADJUSTMENT_BURN_INTERACTION = "ADJUSTMENT_BURN_INTERACTION",
}

export interface CustomerInteractionDto {
  customerNumber: string;
  interactionNumber: string;
  interactionTimestamp: string;
  interactionType: InteractionType;
  customerInteractionType?: CustomerInteractionType;
  externalUnitNumber?: string;
  rewardedInteractions: RewardedInteractionDto[];
  paymentAmount?: string;
  reversalAmount?: string;
  reversalCashValue?: string;
  cashValue?: string;
  bonusPoints?: string;
  totalBonusPoints: string;
  externalReceiptId?: string;
  externalReferenceReceiptId?: string;
  receiptDetails?: ReceiptDetailDto[];
}
export interface ReceiptDetailDto {
  itemInfo: ItemInfoDto;
  amount: string;
  salesPrice: string;
  originalPrice: string;
}
export interface ItemInfoDto {
  productGroupNumber: number;
  productGroupDesc: string;
  subGroupNumber: number;
  subGroupDesc: string;
  supplierNumber: number;
  supplierDesc: string;
  supplierItemText: string;
  refNumber: number;
  rating: number;
  organic: boolean;
  votings: number;
}
export interface RewardedInteractionDto {
  bonusPoints: string;
  bonusReason: string;
}
