import API from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../rootReducer";
import { thunkGetBonusPointsInfo } from "./BonifiedInteractionsActions";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const FIND_COUPONS_BY_CUSTOMER_NUMBER =
  "FIND_COUPONS_BY_CUSTOMER_NUMBER";
export const REDEEM_COUPON = "REDEEM_COUPON";

export const thunkCreateCoupon =
  (templateNumber: string, customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await API.createCoupon(templateNumber, customerNumber);
      dispatch(thunkFindCoupons(customerNumber, true) as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification(`Coupon erfolgreich aktiviert`));
      // update bonus points with delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(thunkGetBonusPointsInfo(customerNumber) as BooleanThunkAction);
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktivieren des Prämiencoupons",
          e
        )
      );
      return false;
    }
  };

export const thunkFindCoupons =
  (customerNumber: string, isDelayed: boolean): BooleanThunk =>
  async (dispatch) => {
    if (isDelayed) await new Promise((resolve) => setTimeout(resolve, 2000));
    try {
      const coupons = await API.findCoupons(customerNumber);
      dispatch({
        type: FIND_COUPONS_BY_CUSTOMER_NUMBER,
        payload: coupons,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Coupons", e)
      );
      return false;
    }
  };
